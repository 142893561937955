import React, { useState } from 'react';
import {
    Button,
    FormControl, FormLabel, Input,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useDisclosure,
    Box,
    Image,
    Icon,
    IconButton,
    Tooltip, useToast,
    Select
} from "@chakra-ui/react";
import { FiFile, FiTrash2 } from 'react-icons/fi';
import dicomParser from "dicom-parser";
import axios from 'axios';
import { SERVER_URL } from "../../../Constants";
import { Display } from "../Button/Button";
import { toast } from "react-toastify";
import { SiteEntity } from "../../../Models/Entities";

interface StudyUploadModalProps {
    sites: SiteEntity[];
}

const StudyUploadModal: React.FC<StudyUploadModalProps> = ({ sites }) => {
    const [error, setError] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isBeingHovered, setIsBeingHovered] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [selectedSite, setSelectedSite] = useState<SiteEntity | null>(null); 

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const clearFileState = () => {
        setError(null);
        setFile(null);
        setPreviewUrl(null);
        setFileName(null);
        setSelectedSite(null); 
    };

    const validateDicomFile = (file: File): boolean => {
        if (file.type !== 'application/dicom' && !file.name.endsWith('.dcm')) {
            setError('Invalid file type. Please upload a DICOM (.dcm) file.');
            return false;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
            try {
                const dataSet = dicomParser.parseDicom(new Uint8Array(fileReader.result as ArrayBuffer));
                if (!dataSet) {
                    setError('File is not a valid DICOM file.');
                } else {
                    setError(null);
                }
            } catch (e) {
                setError('Failed to parse DICOM file.');
            }
        };
        fileReader.readAsArrayBuffer(file);

        return true;
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            validateDicomFile(selectedFile);
            if (selectedFile.type.startsWith('image/')) {
                const url = URL.createObjectURL(selectedFile);
                setPreviewUrl(url);
            } else {
                setPreviewUrl(null);
            }
        }
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsBeingHovered(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            setFileName(droppedFile.name);
            validateDicomFile(droppedFile);
            if (droppedFile.type.startsWith('image/')) {
                const url = URL.createObjectURL(droppedFile);
                setPreviewUrl(url);
            } else {
                setPreviewUrl(null);
            }
        }
    };

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        setIsBeingHovered(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        setIsBeingHovered(false);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setIsBeingHovered(true);
    };

    const handleSubmit = async () => {
        if (!file || error || !selectedSite) {
            return; 
        }

        var datastoreId = await getAhiDatastoreId(selectedSite.ahiDatastoreId);
        
        if(!datastoreId || !selectedSite.aeTitle){
            toast({
                title: 'Failed to upload file',
                description: `Site '${selectedSite.siteName}' has not been configured for uploading studies.`,
                position: 'bottom-right',
                status: 'error',
                duration: 6000,
                isClosable: true,
            });
            setIsUploading(false);
            clearFileState();
            onClose();
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        
        formData.append('file', file);
        formData.append('aeTitle', selectedSite.aeTitle);
        formData.append('siteId', selectedSite.id);
        formData.append('datastoreId', datastoreId);

        try {
            const response = await axios.post(
                `${SERVER_URL}/api/upload/study`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 200) {
                toast({
                    title: 'File uploaded successfully!',
                    description: 'Please allow a few minutes for the file to be processed.',
                    position: 'bottom-right',
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Failed to upload file',
                    description: 'There was a problem when trying to upload your file. Please try again.',
                    position: 'bottom-right',
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: 'Failed to upload file',
                description: 'There was a problem when trying to upload your file. Please try again.',
                position: 'bottom-right',
                status: 'error',
                duration: 6000,
                isClosable: true,
            });
        } finally {
            setIsUploading(false);
            clearFileState();
            onClose();
        }
    };
    
    const getAhiDatastoreId = async (datastoreId: string | undefined) => {
        const response = await axios.get(`${SERVER_URL}/api/entity/AhiDatastoreEntity/${datastoreId}`);

        if (response.status === 200) {
            return response.data.datastoreId
        }
        
        return null;
    }
    
    const handleClose = () => {
        clearFileState();
        onClose();
    };

    const handleChangeFile = () => {
        setFile(null);
        setFileName(null);
        setPreviewUrl(null);
        document.getElementById('dicom-file')?.click();
    };

    const handleRemoveFile = () => {
        clearFileState();
    };

    return (
        <>
            <Button
                className={Display.Solid}
                onClick={onOpen}
                minHeight="4rem"
                minWidth="12rem"
                ml="1"
            >
                Upload Study
            </Button>

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Study</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            {!file && <FormLabel htmlFor="dicom-file">Choose a DICOM file (.dcm)</FormLabel>}
                            <Input
                                type="file"
                                id="dicom-file"
                                accept=".dcm,application/dicom"
                                onChange={handleFileChange}
                                hidden
                            />
                            <Box
                                mt={4}
                                p={4}
                                border="2px dashed"
                                borderColor={isBeingHovered ? 'teal.500' : 'gray.300'}
                                bg={isBeingHovered ? 'gray.100' : 'transparent'}
                                textAlign="center"
                                cursor={file ? 'default' : 'pointer'}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={!file ? () => document.getElementById('dicom-file')?.click() : undefined}
                                position="relative"
                            >
                                {!file ? (
                                    <Text color="gray.500">
                                        Drag & drop your DICOM file here or click to choose
                                    </Text>
                                ) : (
                                    <Box>
                                        {previewUrl ? (
                                            <Image
                                                src={previewUrl}
                                                alt="Preview"
                                                boxSize="100px"
                                                objectFit="contain"
                                                mb={2}
                                            />
                                        ) : (
                                            <Icon as={FiFile} boxSize={8} color="gray.500" mb={2} />
                                        )}
                                        <Text color="gray.700" fontWeight="bold">
                                            {fileName}
                                        </Text>
                                    </Box>
                                )}

                                {file && (
                                    <Tooltip label="Remove selected file" aria-label="Remove selected file">
                                        <IconButton
                                            aria-label="Remove file"
                                            icon={<FiTrash2 />}
                                            position="absolute"
                                            top={2}
                                            right={2}
                                            size="sm"
                                            onClick={handleRemoveFile}
                                            variant="ghost"
                                            color="red.500"
                                            _hover={{ bg: "red.100" }}
                                        />
                                    </Tooltip>
                                )}
                            </Box>

                            {file && (
                                <Text
                                    color="blue.500"
                                    mt={2}
                                    textAlign="center"
                                    textDecoration="underline"
                                    cursor="pointer"
                                    onClick={handleChangeFile}
                                >
                                    Click here to choose a different file
                                </Text>
                            )}

                            {error && (
                                <Text color="red.500" mt={2}>{error}</Text>
                            )}
                            
                            <FormControl mt={4}>
                                <FormLabel htmlFor="site-select">Select Site</FormLabel>
                                <Select
                                    id="site-select"
                                    placeholder="Select a site"
                                    value={selectedSite ? selectedSite.id : undefined}
                                    onChange={(e) => {
                                        const siteId = e.target.value;
                                        const site = sites.find(site => site.id === siteId);
                                        setSelectedSite(site || null);
                                    }}
                                >
                                    {sites.map(site => (
                                        <option key={site.id} value={site.id}>
                                            {site.siteName}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                        <Button
                            colorScheme="blue"
                            onClick={handleSubmit}
                            isLoading={isUploading}
                            isDisabled={!file || Boolean(error) || !selectedSite} 
                        >
                            Upload
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default StudyUploadModal;
